/* eslint-disable @typescript-eslint/no-explicit-any */
import ReactDropdown from 'react-dropdown';
import 'react-dropdown/style.css';

const DropdownWithLabel = (props: {
  options: { value: string; label: string }[];
  onChange: (item: any) => void;
  placeholder: string;
  label: string;
  value?: string;
}) => {
  return (
    <div className="flex flex-col gap-2">
      <div className={`text-[16px] font-bold`}>{props.label}</div>
      <ReactDropdown
        value={props.value}
        options={props.options}
        onChange={props.onChange}
        placeholder={props.placeholder}
        controlClassName="bg-transparent border-solid border-[#C6C6C6] border-[1px]"
      />
    </div>
  );
};

export default DropdownWithLabel;
