const createStyleForBackgroundImage = (
  src: string,
  opts = {
    withDarkOverlay: false,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
) => {
  return {
    backgroundImage: `${
      opts.withDarkOverlay
        ? 'linear-gradient(rgba(0,0,0,.1), rgba(0,0,0,.5)), '
        : ''
    }url(${src})`,
    backgroundSize: opts.backgroundSize,
    backgroundPosition: opts.backgroundPosition,
    backgroundRepeat: opts.backgroundRepeat,
  };
};

export default createStyleForBackgroundImage;
