import API from '@/api';
import { defaultStyles } from '@/utils/defaultStyles';
import { Field, Formik } from 'formik';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { FormikFormField } from './FormikFormField';
import DropdownWithLabel from './DropdownWithLabel';
import { useState } from 'react';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Imię i nazwisko są wymagane'),
  phone: Yup.string().required('Numer telefonu jest wymagany'),
  email: Yup.string().email().required('Email jest wymagany'),
  agreements: Yup.boolean().oneOf([true], 'Zgody są wymagane'),
  message: Yup.string().required('Wiadomość jest wymagana'),
});

const dropdownOptions = [
  { value: 'Inne', label: 'Inne' },
  { value: 'Nieruchomości', label: 'Nieruchomości' },
  { value: 'Spadki', label: 'Spadki' },
  { value: 'Spółki', label: 'Spółki' },
];

export default function ContactFormSection() {
  const [subject, setSubject] = useState<string | null>('Inne');

  const handleSubjectChange = (item: any) => {
    setSubject(item.value);
  };

  return (
    <section
      className={`flex flex-col gap-4 py-12 ${defaultStyles.paddingBig}`}
      id="contactForm"
    >
      <div className="flex flex-col gap-4">
        <div className="tracking-widest text-[35px] md:text-[45px] text-black">
          Formularz kontaktowy
        </div>
        <div className="bg-[#A5A5A5] h-[64px] w-[1px]" />
        <Formik
          initialValues={{
            name: '',
            phone: '',
            email: '',
            message: '',
            agreements: false,
          }}
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            await toast.promise(
              API.post('contact/', {
                name: values.name,
                phone: values.phone,
                email: values.email,
                message: values.message,
                subject: subject,
              }),
              {
                pending: 'Wysyłanie...',
                success: 'Wysłano e-mail',
                error: 'Wystąpił błąd przy wysyłaniu.',
              },
            );
          }}
        >
          {({ values, errors, handleSubmit, isSubmitting }) => (
            <form onSubmit={handleSubmit} className={`flex flex-col gap-4`}>
              <div className={`flex flex-col gap-4`}>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                  <FormikFormField
                    label="Imię i nazwisko*"
                    placeholder="Jan Kowalski"
                    name="name"
                    value={values.name}
                  />
                  <FormikFormField
                    label="E-Mail*"
                    placeholder="mail@mail.com"
                    name="email"
                    value={values.email}
                  />
                  <FormikFormField
                    label="Numer telefonu*"
                    placeholder="+48000000000"
                    name="phone"
                    value={values.phone}
                  />
                  <DropdownWithLabel
                    value="inne"
                    options={dropdownOptions}
                    placeholder="Temat"
                    label="Kategoria wiadomości*"
                    onChange={handleSubjectChange}
                  />
                </div>
                <FormikFormField
                  label="Treść wiadomości*"
                  type="textarea"
                  placeholder="Wiadomość..."
                  name="message"
                  value={values.message}
                />
                <div className="grid grid-cols-1 gap-4">
                  <label className="text-[12px]">
                    <Field type="checkbox" name="agreements" />
                    Wyrażam zgodę na przetwarzanie moich danych osobowych przez
                    Kancelaria Notarialna Maria Czarniak Patrycja Dorczyńska
                    Sara Podkówka Spółka Cywilna reprezentowany przez: w celu i
                    w zakresie niezbędnym do realizacji obsługi niniejszego
                    zgłoszenia. Zapoznałem się z treścią informacji o sposobie
                    przetwarzania moich danych osobowych udostępnionych pod
                    adresem{' '}
                    <a href="/rodo" target="_blank">
                      /rodo
                    </a>
                  </label>
                </div>
                {Object.keys(errors).length > 0 && (
                  <div className="text-red-500 text-xs">
                    <ul>
                      {Object.values(errors).map((error, index) => (
                        <li key={index}>{error}</li>
                      ))}
                    </ul>
                  </div>
                )}
                <button
                  type="submit"
                  className="bg-transparent text-[16px] uppercase tracking-widest text-[#CBBA9D] border-solid border-[#CBBA9D] outline-none p-4 w-[184px] cursor-pointer"
                >
                  Wyślij
                </button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </section>
  );
}
