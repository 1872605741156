import { defaultStyles } from '@/utils/defaultStyles';

const items = [
  {
    svg: (
      <svg
        width="58"
        height="58"
        viewBox="0 0 58 58"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_99_565)">
          <path
            d="M58 29.155V0H28.845V29.155H41.9158V54.6016H26.2576C26.9634 53.6553 27.474 52.5559 27.7329 51.3638H36.9716V42.4649C36.9716 37.2698 32.7449 33.0431 27.5497 33.0431H23.8287L16.7687 22.5174H0V51.3639H11.2671C11.526 52.556 12.0366 53.6554 12.7424 54.6017H0V58.0001H58V54.6017H45.3142V29.155H58ZM14.9561 25.9157L19.7367 33.043H3.39844V25.9157H14.9561ZM3.39844 36.4416H27.5497C29.965 36.4416 32.0517 37.8713 33.011 39.9282H28.9993V43.3267H33.573V47.9653H27.7702C27.0171 44.0861 23.596 41.1477 19.4999 41.1477C15.4038 41.1477 11.9828 44.0861 11.2296 47.9653H3.39844V36.4416ZM14.4724 49.5739C14.4724 46.8016 16.7278 44.5463 19.5 44.5463C22.2722 44.5463 24.5277 46.8017 24.5277 49.5739C24.5277 52.3461 22.2723 54.6016 19.5 54.6016C16.7277 54.6016 14.4724 52.3461 14.4724 49.5739ZM32.2435 3.39844H54.6016V25.7565H32.2435V3.39844Z"
            fill="black"
          />
          <path
            d="M45.5772 6.58154H37.7665V22.311H41.1649V16.5929H45.5772C48.3373 16.5929 50.5829 14.3473 50.5829 11.5872C50.5829 8.82712 48.3373 6.58154 45.5772 6.58154ZM45.5772 13.1944H41.1649V9.97998H45.5772C46.4634 9.97998 47.1845 10.701 47.1845 11.5872C47.1845 12.4734 46.4634 13.1944 45.5772 13.1944Z"
            fill="black"
          />
        </g>
        <defs>
          <clipPath id="clip0_99_565">
            <rect width="58" height="58" fill="white" />
          </clipPath>
        </defs>
      </svg>
    ),
    title: (
      <>
        <span className="font-bold">BEZPŁATNY PARKING</span> POD OKNAMI
        KANCELARII
      </>
    ),
  },
  {
    svg: (
      <svg
        width="58"
        height="58"
        viewBox="0 0 58 58"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_99_634)">
          <path
            d="M51.1748 35.2774V6.82531H44.3496V5.11182C44.3496 2.29315 42.0565 0 39.2378 0C36.4191 0 34.126 2.29315 34.126 5.11182V6.82531H17.0488V5.11182C17.0488 2.29315 14.7557 0 11.937 0C9.11835 0 6.8252 2.29315 6.8252 5.11182V6.82531H0V51.1748H35.2774C37.1959 55.2064 41.3091 58 46.063 58C52.6451 58 58 52.6451 58 46.063C58 41.3091 55.2064 37.1959 51.1748 35.2774ZM37.5244 5.11182C37.5244 4.16705 38.293 3.39844 39.2378 3.39844C40.1826 3.39844 40.9512 4.16705 40.9512 5.11182V6.82531H37.5244V5.11182ZM10.2236 5.11182C10.2236 4.16705 10.9922 3.39844 11.937 3.39844C12.8818 3.39844 13.6504 4.16705 13.6504 5.11182V6.82531H10.2236V5.11182ZM3.39844 47.7764V10.2237H6.8252V11.9371C6.8252 14.7558 9.11835 17.0489 11.937 17.0489H12.8433V13.6505H11.937C10.9922 13.6505 10.2236 12.8819 10.2236 11.9371V10.2237H34.126V11.9371C34.126 14.7558 36.4191 17.0489 39.2378 17.0489H40.144V13.6505H39.2378C38.293 13.6505 37.5244 12.8819 37.5244 11.9371V10.2237H47.7764V34.2495C47.2166 34.1687 46.6447 34.126 46.063 34.126C43.3743 34.126 40.8905 35.0199 38.8926 36.5257V24.6517H42.3649V21.2533H32.0218V24.6517H35.4941V36.7468H38.6087C35.8779 38.9363 34.1259 42.2987 34.1259 46.063C34.1259 46.6447 34.1686 47.2166 34.2493 47.7764H3.39844ZM46.063 54.6016C41.3548 54.6016 37.5244 50.7712 37.5244 46.063C37.5244 41.3548 41.3548 37.5244 46.063 37.5244C50.7712 37.5244 54.6016 41.3548 54.6016 46.063C54.6016 50.7712 50.7712 54.6016 46.063 54.6016Z"
            fill="black"
          />
          <path
            d="M50.2368 41.624L44.9633 46.8977L41.8892 43.8236L39.4861 46.2266L44.9633 51.7038L52.6399 44.0271L50.2368 41.624Z"
            fill="black"
          />
        </g>
        <defs>
          <clipPath id="clip0_99_634">
            <rect width="58" height="58" fill="white" />
          </clipPath>
        </defs>
      </svg>
    ),
    title: (
      <>
        PRACUJĄCA <span className="font-bold">SOBOTA</span>
      </>
    ),
  },
  {
    svg: (
      <svg
        width="58"
        height="58"
        viewBox="0 0 58 58"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_104_671)">
          <path
            d="M34.5267 42.6468L38.5868 49.6794H46.7073L50.7674 42.6468L46.7073 35.6143H38.5868L34.5267 42.6468ZM44.7376 39.026L46.828 42.6468L44.7376 46.2676H40.5566L38.4662 42.6468L40.5566 39.026H44.7376Z"
            fill="black"
          />
          <path
            d="M44.3529 27.2941V0H13.6471V27.2941H0V58H58V27.2941H44.3529ZM17.0588 3.41176H40.9412V27.2941C40.194 27.2941 17.8044 27.2941 17.0588 27.2941V3.41176ZM27.2941 54.5882H3.41176V30.7059H27.2941V54.5882ZM54.5882 54.5882H30.7059V30.7059H54.5882V54.5882Z"
            fill="black"
          />
          <path
            d="M37.5294 15.3531C37.5294 10.65 33.7031 6.82373 29 6.82373C17.7001 7.25248 17.7032 23.4555 29.0001 23.8826C33.7031 23.8826 37.5294 20.0563 37.5294 15.3531ZM23.8823 15.3531C23.8823 12.5313 26.1781 10.2355 29 10.2355C35.78 10.4927 35.778 20.2146 28.9999 20.4708C26.1781 20.4708 23.8823 18.1751 23.8823 15.3531Z"
            fill="black"
          />
          <path
            d="M23.9638 51.1766L15.353 34.6304L6.74213 51.1766H23.9638ZM18.3421 47.7649H12.3638L15.353 42.0209L18.3421 47.7649Z"
            fill="black"
          />
        </g>
        <defs>
          <clipPath id="clip0_104_671">
            <rect width="58" height="58" fill="white" />
          </clipPath>
        </defs>
      </svg>
    ),
    title: (
      <>
        <span className="font-bold">KĄCIK DZIECIĘCY</span>
      </>
    ),
  },
  {
    svg: (
      <svg
        width="58"
        height="58"
        viewBox="0 0 58 58"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M29 46.304L21.9973 39.3012C20.0099 37.3138 20.0099 34.08 21.9973 32.0926C23.9298 30.1601 27.0059 30.1091 29 31.8975C30.9944 30.1088 34.0706 30.1603 36.0027 32.0926C37.9901 34.08 37.9901 37.3138 36.0027 39.3012L29 46.304ZM24.4003 34.4956C23.7358 35.1603 23.7359 36.2336 24.4003 36.8982L29 41.4979L33.5997 36.8982C34.2642 36.2335 34.2641 35.1602 33.5997 34.4956C32.9372 33.8332 31.8596 33.8332 31.1971 34.4956L29 36.6928L26.8028 34.4956C26.1403 33.8332 25.0627 33.8332 24.4003 34.4956Z"
          fill="black"
        />
        <path
          d="M38.823 54.7819C35.6921 53.4709 32.1437 51.9849 29 51.9849C25.8563 51.9849 22.3079 53.4709 19.177 54.7819C15.1554 56.466 11.6824 57.9202 9.18507 56.259C7.6543 55.2403 6.91016 53.2865 6.91016 50.2856C6.91016 43.8837 8.91376 36.8844 12.2698 31.5625C16.1472 25.4137 21.8443 21.3989 29.0001 21.3989C36.1547 21.3989 41.8521 25.4124 45.7304 31.5625C49.0862 36.8844 51.0898 43.8837 51.0898 50.2856C51.0898 53.2865 50.3457 55.2403 48.8149 56.2589C46.1737 58.0162 42.2748 56.2274 38.823 54.7819ZM29 48.5864C32.8264 48.5864 36.7096 50.2125 40.1357 51.6473C41.7597 52.3273 45.8445 54.1533 46.9323 53.4295C47.1104 53.311 47.6914 52.7341 47.6914 50.2856C47.6914 44.4987 45.8837 38.177 42.8557 33.3752C40.3879 29.4621 35.981 24.7974 28.9999 24.7974C22.0188 24.7974 17.6119 29.4621 15.1441 33.3752C12.1163 38.177 10.3086 44.4986 10.3086 50.2856C10.3086 52.7341 10.8896 53.311 11.0677 53.4295C12.0492 54.0825 15.2736 52.7322 17.8643 51.6473C21.2904 50.2125 25.1736 48.5864 29 48.5864Z"
          fill="black"
        />
        <path
          d="M20.5039 18.0005C16.9912 18.0005 13.707 14.541 13.707 9.50439C13.707 4.41773 17.0271 1.0083 20.5039 1.0083C24.0166 1.0083 27.3008 4.4678 27.3008 9.50439C27.3008 14.5911 23.9807 18.0005 20.5039 18.0005ZM20.5039 4.40674C18.6617 4.40674 17.1055 6.74113 17.1055 9.50439C17.1055 12.2677 18.6617 14.6021 20.5039 14.6021C22.3461 14.6021 23.9023 12.2677 23.9023 9.50439C23.9023 6.74113 22.3461 4.40674 20.5039 4.40674Z"
          fill="black"
        />
        <path
          d="M51.2031 28.1958C47.6904 28.1958 44.4062 24.7363 44.4062 19.6997C44.4062 14.613 47.7263 11.2036 51.2031 11.2036C54.7159 11.2036 58 14.6631 58 19.6997C58 24.7864 54.68 28.1958 51.2031 28.1958ZM51.2031 14.6021C49.3609 14.6021 47.8047 16.9364 47.8047 19.6997C47.8047 22.463 49.3609 24.7974 51.2031 24.7974C53.0453 24.7974 54.6016 22.463 54.6016 19.6997C54.6016 16.9364 53.0453 14.6021 51.2031 14.6021Z"
          fill="black"
        />
        <path
          d="M6.79688 28.1958C3.28414 28.1958 0 24.7363 0 19.6997C0 14.613 3.32005 11.2036 6.79688 11.2036C10.3096 11.2036 13.5938 14.6631 13.5938 19.6997C13.5938 24.7864 10.2737 28.1958 6.79688 28.1958ZM6.79688 14.6021C4.9547 14.6021 3.39844 16.9364 3.39844 19.6997C3.39844 22.463 4.9547 24.7974 6.79688 24.7974C8.63905 24.7974 10.1953 22.463 10.1953 19.6997C10.1953 16.9364 8.63905 14.6021 6.79688 14.6021Z"
          fill="black"
        />
        <path
          d="M37.4961 18.0005C33.9834 18.0005 30.6992 14.541 30.6992 9.50439C30.6992 4.41773 34.0193 1.0083 37.4961 1.0083C41.0088 1.0083 44.293 4.4678 44.293 9.50439C44.293 14.5911 40.9729 18.0005 37.4961 18.0005ZM37.4961 4.40674C35.6539 4.40674 34.0977 6.74113 34.0977 9.50439C34.0977 12.2677 35.6539 14.6021 37.4961 14.6021C39.3383 14.6021 40.8945 12.2677 40.8945 9.50439C40.8945 6.74113 39.3383 4.40674 37.4961 4.40674Z"
          fill="black"
        />
      </svg>
    ),
    title: (
      <>
        PRZYJAZNE <span className="font-bold">ZWIERZĘTOM</span>
      </>
    ),
  },
  {
    svg: (
      <svg
        width="58"
        height="58"
        viewBox="0 0 58 58"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_99_578)">
          <path
            d="M51.1053 24.7285L49.8477 23.5581C44.1867 18.2895 36.7829 15.3881 28.9999 15.3881C21.2169 15.3881 13.8133 18.2897 8.1522 23.5582L6.8946 24.7286L0 17.1853L1.23888 16.0359C8.78941 9.03069 18.6485 5.17285 29 5.17285C39.3515 5.17285 49.2106 9.03069 56.7611 16.0359L58 17.1853L51.1053 24.7285ZM29 11.983C37.0687 11.983 44.7732 14.7923 50.8808 19.927L53.1807 17.4108C46.4431 11.7022 37.9221 8.57793 28.9999 8.57793C20.0777 8.57793 11.5568 11.7023 4.8192 17.4109L7.1191 19.9272C13.2268 14.7923 20.9313 11.983 29 11.983Z"
            fill="black"
          />
          <path
            d="M39.6088 37.3066L38.3507 36.1154C33.1948 31.2345 24.8056 31.2344 19.6496 36.1154L18.3914 37.3066L11.4912 29.7575L12.7195 28.6077C17.1103 24.4981 22.8921 22.2349 29.0001 22.2349C35.1081 22.2349 40.89 24.4981 45.2807 28.6077L46.509 29.7575L39.6088 37.3066ZM16.3213 29.995L18.6268 32.5174C21.5718 30.2549 25.1949 29.0258 29 29.0258C32.8051 29.0258 36.4282 30.2549 39.3732 32.5174L41.6787 29.995C34.2837 24.1363 23.7163 24.1363 16.3213 29.995Z"
            fill="black"
          />
          <path
            d="M29 52.8273C25.2449 52.8273 22.1898 49.7723 22.1898 46.0172C22.1898 42.2621 25.2449 39.207 29 39.207C32.7551 39.207 35.8101 42.2621 35.8101 46.0172C35.8101 49.7723 32.7551 52.8273 29 52.8273ZM29 42.6121C27.1224 42.6121 25.5949 44.1396 25.5949 46.0172C25.5949 47.8947 27.1224 49.4223 29 49.4223C30.8776 49.4223 32.4051 47.8947 32.4051 46.0172C32.4051 44.1396 30.8776 42.6121 29 42.6121Z"
            fill="black"
          />
        </g>
        <defs>
          <clipPath id="clip0_99_578">
            <rect width="58" height="58" fill="white" />
          </clipPath>
        </defs>
      </svg>
    ),
    title: (
      <>
        <span className="font-bold">BEZPŁATNE</span> WI-FI
      </>
    ),
  },
  {
    svg: (
      <svg
        width="58"
        height="58"
        viewBox="0 0 58 58"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 29H58V5.21094H0V29ZM11.3587 25.6016L13.0579 22.2031H44.942L46.6412 25.6016H11.3587ZM3.39844 8.60938H54.6016V25.6016H50.4407L47.0423 18.8047H10.9576L7.55914 25.6016H3.39844V8.60938Z"
          fill="black"
        />
        <path
          d="M6.91016 12.0078H10.3086V15.4062H6.91016V12.0078Z"
          fill="black"
        />
        <path
          d="M13.707 12.0078H17.1055V15.4062H13.707V12.0078Z"
          fill="black"
        />
        <path
          d="M14.0344 45.8155C12.5247 48.5255 10.9941 49.7259 10.9788 49.7377L10.9883 49.7305L13.0273 52.4492C13.3326 52.2203 20.5039 46.6777 20.5039 32.3984H17.1055C17.1055 38.931 15.4354 43.3004 14.0344 45.8155Z"
          fill="black"
        />
        <path
          d="M43.9656 45.8155C42.5646 43.3004 40.8945 38.931 40.8945 32.3984H37.4961C37.4961 46.6777 44.6674 52.2203 44.9727 52.4492L47.0117 49.7305L47.0212 49.7376C47.0059 49.7258 45.4754 48.5255 43.9656 45.8155Z"
          fill="black"
        />
        <path
          d="M27.3008 32.3984H30.6992V52.7891H27.3008V32.3984Z"
          fill="black"
        />
      </svg>
    ),
    title: (
      <>
        <span className="font-bold">KLIMATYZACJA</span>
      </>
    ),
  },
];

function AmenitiesItem(props: { svg: JSX.Element; title: JSX.Element }) {
  return (
    <div className="flex flex-col items-center gap-4 w-full md:w-[236px]">
      <div className="flex flex-col justify-center items-center rounded-full bg-[#efe5e0] min-w-[120px] max-w-[120px] min-h-[120px] max-h-[120px]">
        {props.svg}
      </div>
      <div className="w-full h-[1px] bg-[#858585]" />
      <div className="text-[25px] text-black uppercase text-center">
        {props.title}
      </div>
    </div>
  );
}

export default function AmenitiesSection() {
  return (
    <section
      className={`flex flex-col justify-center items-center gap-4 py-24 ${defaultStyles.padding}`}
      id="amenities"
    >
      <div className="text-black text-[35px] md:text-[45px] font-bold">
        Udogodnienia
      </div>
      <div className="bg-[#A5A5A5] h-[64px] w-[1px]" />
      <div className="flex flex-row gap-8 flex-wrap justify-center">
        {items.map((item, index) => (
          <AmenitiesItem key={index} {...item} />
        ))}
      </div>
    </section>
  );
}
